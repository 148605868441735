import moment from 'moment';
import { Genders } from './Genders';
import { DietType } from './MedicalTest';
import { Intolerances } from './PatientInfo';
import { UserTableDto } from './User';

class MenuDayCreateFormDto {
  weeks: number;
  startDate: string;

  constructor(number: number, startDate: string) {
    this.weeks = number;
    this.startDate = startDate;
  }
}

class NutritionalPlanCreateFormDto {
  name: string;
  constructor(nutritionalPlan: NutritionalPlan) {
    this.name = nutritionalPlan.name;
  }
}

class NutritionalPlanFormDto {
  id: string;
  name: string;
  gender: Genders;
  professional: UserTableDto;
  weight: number;
  height: number;
  birthdate: any;
  foodIntolerances: Intolerances[];
  activityFrequency: number;
  dietType: DietType;
  numberOfMeals: number;
  meals: Meal[];
  currentCalories: number;
  proteinShare: number;
  fatShare: number;
  carbohydratesShare: number;
  enabled: boolean;

  constructor(nutritionalPlan: NutritionalPlan) {
    this.id = nutritionalPlan.id;
    this.name = nutritionalPlan.name;
    this.gender = nutritionalPlan.gender;
    this.professional = nutritionalPlan.professional;
    this.weight = nutritionalPlan.weight;
    this.height = nutritionalPlan.height;
    this.birthdate = nutritionalPlan.birthdate ? new Date(nutritionalPlan.birthdate) : undefined;
    this.foodIntolerances = nutritionalPlan.foodIntolerances ?? [];
    this.activityFrequency = nutritionalPlan.activityFrequency;
    this.dietType = nutritionalPlan.dietType;
    this.numberOfMeals = nutritionalPlan.numberOfMeals ?? nutritionalPlan.meals ? nutritionalPlan.meals.length : 0;
    this.meals = nutritionalPlan.meals;
    this.currentCalories = nutritionalPlan.currentCalories;
    this.proteinShare = nutritionalPlan.proteinShare;
    this.fatShare = nutritionalPlan.fatShare;
    this.carbohydratesShare = nutritionalPlan.carbohydratesShare;
    this.enabled = nutritionalPlan.enabled ? nutritionalPlan.enabled : false;
  }
}

function updateNutritionalPlanData(nutritionalPlan: NutritionalPlanFormDto) {
  const updateData = { ...nutritionalPlan };
  updateData.birthdate = moment(updateData.birthdate).format('YYYY-MM-DDTHH:mm:ss');

  return updateData;
}

enum MacroType {
  Protein = 'PROTEIN',
  Carbs = 'CARBS',
  Fat = 'FAT',
}

function getMacroIcon(macro: MacroType) {
  if (macro === MacroType.Fat) {
    return 'icon-fats';
  }
  if (macro === MacroType.Protein) {
    return 'icon-proteins';
  }

  return 'icon-hc';
}

interface MenuDay {
  date: string;
  meals: Meal[];
  nutritionalPlan: NutritionalPlan;
  nutritionalPlanId: string;
  professional: UserTableDto;
}
enum MealType {
  BREAKFAST = 'BREAKFAST',
  MID_MORNING_SNACK = 'MID_MORNING_SNACK',
  MID_AFTERNOON_SNACK = 'MID_AFTERNOON_SNACK',
  LUNCH = 'LUNCH',
  DINNER = 'DINNER',
}

interface MealDetails {
  macroType: MacroType;
  share: number;
  bites: number;
  calories: number;
  quantity: number;
}

interface Meal {
  name: string;
  share: number;
  mealType: MealType;
  mealDetails: MealDetails[];
}

class MealFormDto {
  name: string;
  share: number;
  mealType: MealType;
  mealDetails: MealDetails[];
  constructor(meal: Meal) {
    this.name = meal.name;
    this.share = meal.share;
    this.mealType = meal.mealType;
    this.mealDetails = meal.mealDetails;
  }
}

interface NutritionalPlan {
  id: string;
  patientId: string;
  name: string;
  gender: Genders;
  professional: UserTableDto;
  weight: number;
  height: number;
  birthdate: string;
  foodIntolerances: Intolerances[];
  basalCalories: number;
  activityFrequency: number;
  totalCalories: number;
  dietType: DietType;
  numberOfMeals: number;
  meals: Meal[];
  currentCalories: number;
  proteinShare: number;
  fatShare: number;
  carbohydratesShare: number;
  defaultMeals: MenuMeal[];
  creationDate: string;
  enabled: boolean | null;
}

interface Food {
  id: string;
  quantity: number;
  calories: number;
  protein: number;
  fat: number;
  carbohydrates: number;
  proteinBites: number;
  fatBites: number;
  carbohydratesBites: number;
  units: string;
  macroType: MacroType;
  foodIntolerances: Intolerances[];
}

interface MenuMealDetail {
  bites: number;
  foodId: string;
  food: Food;
}

interface MenuMeal {
  id: string;
  name: string | null;
  proteinBites: number | null;
  fatBites: number | null;
  carbohydratesBites: number | null;
  mealType: MealType;
  mealDetails: MenuMealDetail[];
  completed: boolean;
}

interface DietDay {
  day: number;
  nutritionalPlanId: string;
  nutritionalPlan: NutritionalPlan;
  meals: MenuMeal[];
}

interface Diet {
  id: string;
  professional: UserTableDto;
  dietDays: DietDay[];
}

interface FoodDto {
  id: string;
  quantity: number;
  calories: number;
  protein: number;
  fat: number;
  carbohydrates: number;
  proteinBites: number;
  fatBites: number;
  carbohydratesBites: number;
  units: string;
  foodIntolerances?: Intolerances[];
}

interface MenuMealDetailDto {
  bites: number;
  foodId: string;
  food: FoodDto;
}

class MenuMealDto {
  id: string;
  name: string | null;
  proteinBites: number | null;
  fatBites: number | null;
  mealType: MealType | null;
  carbohydratesBites: number | null;
  mealDetails: MenuMealDetailDto[];
  completed: boolean;

  constructor(menuMeal: MenuMeal) {
    this.id = menuMeal.id;
    this.name = menuMeal.name;
    this.proteinBites = menuMeal.proteinBites;
    this.fatBites = menuMeal.fatBites;
    this.carbohydratesBites = menuMeal.carbohydratesBites;
    this.mealType = menuMeal.mealType;
    this.mealDetails = menuMeal.mealDetails;
    this.completed = menuMeal.completed;
  }
}

interface UpdateMenuMealDto {
  mealDetails: MenuMealDetailDto[];
}

export function updateMenuMealFromMenuMealDto(menuMealDetails: MenuMealDetailDto[]): UpdateMenuMealDto {
  const newMenuMeal: UpdateMenuMealDto = {
    mealDetails: menuMealDetails,
  };
  return newMenuMeal;
}

class DietDayFormDto {
  nutritionalPlanId: string;
  constructor(nutritionalPlanId: string) {
    this.nutritionalPlanId = nutritionalPlanId;
  }
}

export {
  NutritionalPlan,
  Meal,
  MealDetails,
  MacroType,
  getMacroIcon,
  MealType,
  NutritionalPlanFormDto,
  NutritionalPlanCreateFormDto,
  MealFormDto,
  updateNutritionalPlanData,
  UpdateMenuMealDto,
  Diet,
  DietDay,
  MenuDay,
  Food,
  MenuMealDto,
  MenuMealDetailDto,
  MenuMeal,
  MenuMealDetail,
  DietDayFormDto,
  MenuDayCreateFormDto,
};
