import {
  Diet,
  DietDayFormDto,
  Food,
  MealFormDto,
  MealType,
  MenuDay,
  NutritionalPlan,
  NutritionalPlanCreateFormDto,
  NutritionalPlanFormDto,
  UpdateMenuMealDto,
} from '@/models/NutritionalPlan';
import { useProfileStore } from '@/store/profile.module';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import handleError from './util/ErrorHandler';
import { ServiceError } from './util/ServiceError';

let axiosInstance: AxiosInstance;
const storeProfile = useProfileStore();

class NutritionalPlanService {
  constructor() {
    axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
      headers: {
        'Content-type': 'application/json',
      },
    });
  }

  async create(patientId: string, nutrition: NutritionalPlanCreateFormDto): Promise<NutritionalPlan | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.post(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/nutritional-plan?accountId=${storeProfile.accountId}&patientId=${patientId}`,
        nutrition,
      );
      return networkResponse.data as NutritionalPlan;
    } catch (error) {
      return handleError(error);
    }
  }

  async update(
    patientId: string,
    id: string,
    updatePlan: NutritionalPlanFormDto,
    updateMeals = false,
  ): Promise<NutritionalPlan | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    const options: AxiosRequestConfig = {
      params: {
        updateMeals,
      },
    };

    try {
      const networkResponse = await axiosInstance.put(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/nutritional-plan/${id}?accountId=${storeProfile.accountId}&patientId=${patientId}`,
        updatePlan,
        options,
      );
      return networkResponse.data as NutritionalPlan;
    } catch (error) {
      return handleError(error);
    }
  }

  async updateEnabled(patientId: string, id: string, enabled: boolean): Promise<NutritionalPlan | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    const options: AxiosRequestConfig = {
      params: {},
    };

    try {
      const networkResponse = await axiosInstance.put(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/nutritional-plan/${id}/enabled?accountId=${storeProfile.accountId}&patientId=${patientId}`,
        {
          enabled: enabled,
        },
        options,
      );
      return networkResponse.data as NutritionalPlan;
    } catch (error) {
      return handleError(error);
    }
  }

  async updateNutritionalPlanMeal(
    patientId: string,
    id: string,
    mealType: MealType,
    meal: MealFormDto,
    updateMeals = false,
  ): Promise<NutritionalPlan | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    const options: AxiosRequestConfig = {
      params: {
        updateMeals,
      },
    };

    try {
      const networkResponse = await axiosInstance.put(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/nutritional-plan/${id}/meal/${mealType}?accountId=${storeProfile.accountId}&patientId=${patientId}`,
        meal,
        options,
      );
      return networkResponse.data as NutritionalPlan;
    } catch (error) {
      return handleError(error);
    }
  }

  async updateDefaultMeal(
    patientId: string,
    nutritionalPlanId: string,
    mealId: string,
    mealDetails: UpdateMenuMealDto,
    updateMeals = false,
  ): Promise<NutritionalPlan | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    const options: AxiosRequestConfig = {
      params: {
        updateMeals,
      },
    };

    try {
      const networkResponse = await axiosInstance.put(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/nutritional-plan/${nutritionalPlanId}/defaultMeal/${mealId}?accountId=${storeProfile.accountId}&patientId=${patientId}`,
        mealDetails,
        options,
      );
      return networkResponse.data as NutritionalPlan;
    } catch (error) {
      return handleError(error);
    }
  }

  async findAll(patientId: string): Promise<NutritionalPlan[] | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    const options: AxiosRequestConfig = {
      params: {
        patientId,
      },
    };

    try {
      const response = await axiosInstance.get<NutritionalPlan[]>(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/nutritional-plan?accountId=${storeProfile.accountId}`,
        options,
      );

      console.debug(`>[NutritionService] Succeed : request findAll. Response data: `, response.data);
      return response.data;
    } catch (error) {
      console.debug(`>[NutritionService] Error: request findAll`);
      return handleError(error);
    }
  }

  async findById(patientId: string, nutritionalPlanId: string): Promise<NutritionalPlan | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    const options: AxiosRequestConfig = {
      params: {
        patientId,
      },
    };

    try {
      const response = await axiosInstance.get<NutritionalPlan>(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/nutritional-plan/${nutritionalPlanId}?accountId=${storeProfile.accountId}`,
        options,
      );

      console.debug(`>[NutritionService] Succeed : request findById. Response data: `, response.data);
      return response.data;
    } catch (error) {
      console.debug(`>[NutritionService] Error: request findById`);
      return handleError(error);
    }
  }

  async delete(patientId: string, id: string): Promise<void | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.delete(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/nutritional-plan/${id}?accountId=${storeProfile.accountId}&patientId=${patientId}`,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async findDiet(patientId: string): Promise<Diet | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    const options: AxiosRequestConfig = {
      params: {
        patientId,
      },
    };

    try {
      const response = await axiosInstance.get<Diet | ServiceError>(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/diet?accountId=${storeProfile.accountId}`,
        options,
      );

      console.debug(`>[NutritionService] Succeed : request findAll. Response data: `, response.data);
      return response.data;
    } catch (error) {
      console.debug(`>[NutritionService] Error: request findDiet`);
      return handleError(error);
    }
  }

  async findAllFoods(): Promise<Food[] | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    try {
      const response = await axiosInstance.get<Food[]>(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/food?accountId=${storeProfile.accountId}`,
      );

      console.debug(`>[NutritionService] Succeed : request findAllFoods. Response data: `, response.data);
      return response.data;
    } catch (error) {
      console.debug(`>[NutritionService] Error: request findAllFoods`);
      return handleError(error);
    }
  }

  async updateMenuMeal(
    patientId: string,
    day: number,
    idMeal: string,
    mealDetails: UpdateMenuMealDto,
  ): Promise<void | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const response = await axiosInstance.put(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/diet/day/${day}/meal/${idMeal}?accountId=${storeProfile.accountId}&patientId=${patientId}`,
        mealDetails,
      );

      console.debug(`>[NutritionService] Succeed : request updateMenuMeal. Response data: `, response.data);
      return response.data;
    } catch (error) {
      console.debug(`>[NutritionService] Error: request updateMenuMeal`);
      return handleError(error);
    }
  }

  async updateDietDay(patientId: string, day: number, dietDayRq: DietDayFormDto): Promise<Diet | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.put(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/diet/day/${day}?accountId=${storeProfile.accountId}&patientId=${patientId}`,
        dietDayRq,
      );
      return networkResponse.data as Diet;
    } catch (error) {
      return handleError(error);
    }
  }

  async createMenuPlanification(
    patientId: string,
    startDate: string,
    endDate: string,
  ): Promise<MenuDay | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.post(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/menuDay?accountId=${storeProfile.accountId}&patientId=${patientId}`,
        { startDate, endDate },
      );
      return networkResponse.data as MenuDay;
    } catch (error) {
      return handleError(error);
    }
  }

  async findMenuDay(patientId: string, date: string): Promise<MenuDay | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.get(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/menuDay/${date}?accountId=${storeProfile.accountId}&patientId=${patientId}`,
      );
      return networkResponse.data as MenuDay;
    } catch (error) {
      return handleError(error);
    }
  }

  async updateMenuDayMeal(
    patientId: string,
    mealId: string,
    date: string,
    mealDetails: UpdateMenuMealDto,
  ): Promise<MenuDay | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const response = await axiosInstance.put(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/menuDay/${date}/meal/${mealId}?accountId=${storeProfile.accountId}&patientId=${patientId}`,
        mealDetails,
      );

      console.debug(`>[NutritionService] Succeed : request updateMenuDayMeal. Response data: `, response.data);
      return response.data;
    } catch (error) {
      console.debug(`>[NutritionService] Error: request updateMenuDayMeal`);
      return handleError(error);
    }
  }

  async updateMenuDay(patientId: string, date: string, dietDayRq: DietDayFormDto): Promise<MenuDay | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.put(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/menuDay/${date}?accountId=${storeProfile.accountId}&patientId=${patientId}`,
        dietDayRq,
      );
      return networkResponse.data as MenuDay;
    } catch (error) {
      return handleError(error);
    }
  }

  async deleteMenuDay(patientId: string, date: string): Promise<void | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.delete(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/menuDay/${date}?accountId=${storeProfile.accountId}&patientId=${patientId}`,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }
  async createMenuDay(patientId: string, date: string, dietDayRq: DietDayFormDto): Promise<MenuDay | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.post(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/menuDay/${date}?accountId=${storeProfile.accountId}&patientId=${patientId}`,
        dietDayRq,
      );
      return networkResponse.data as MenuDay;
    } catch (error) {
      return handleError(error);
    }
  }

  async updateAlternativeMealOnDefaultNutritionalPlan(
    nutritionalPlanId: string,
    mealId: string,
    patientId: string,
  ): Promise<MenuDay | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.put(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/nutritional-plan/${nutritionalPlanId}/defaultMeal/${mealId}/alternative?accountId=${storeProfile.accountId}&patientId=${patientId}`,
      );
      return networkResponse.data as MenuDay;
    } catch (error) {
      return handleError(error);
    }
  }

  async updateAlternativeMealOnDiet(patientId: string, day: number, mealId: string): Promise<MenuDay | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.put(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/diet/day/${day}/meal/${mealId}/alternative?accountId=${storeProfile.accountId}&patientId=${patientId}`,
      );
      return networkResponse.data as MenuDay;
    } catch (error) {
      return handleError(error);
    }
  }

  async updateAlternativeMealOnMenuDay(
    patientId: string,
    day: number,
    mealId: string,
    date: string,
  ): Promise<MenuDay | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.put(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/menuDay/${date}/day/${day}/meal/${mealId}/alternative?accountId=${storeProfile.accountId}&patientId=${patientId}`,
      );
      return networkResponse.data as MenuDay;
    } catch (error) {
      return handleError(error);
    }
  }

  async getPdfFile(patientId: string, date: string): Promise<any | ServiceError> {
    axiosInstance.defaults.headers['authorization'] = 'Bearer ' + storeProfile.authToken;
    axiosInstance.defaults.headers['Content-Type'] = 'application/json';

    const options: AxiosRequestConfig = {
      params: {
        patientId,
        date: date,
      },
    };

    try {
      const networkResponse = await axiosInstance.get<Map<string, any>>(
        `${process.env.VUE_APP_API_NUTRITIONAL_PLAN_SERVICE}/menuDay/${date}/pdf?accountId=${storeProfile.accountId}`,
        options,
      );
      const response = networkResponse.data;
      return response;
    } catch (error) {
      return handleError(error);
    }
  }
}

export const nutritionalPlanService = new NutritionalPlanService();
